header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 0 8px;
}

header .left {
    width: 50%;
}

header .right {
    display: flex;
    flex-direction: column;
    text-align: right;
    width: 50%;
    padding: 0.6em;
}

.Home {
    margin: 12px;
}

.Home .newgame {
    font-size: 1.6em;
}

.Players {
    margin: 12px;
}

.Players .PlayerRow {
    font-size: 1.5em;
}

.AddPlayerForm {
    text-align: center;
}

.AddPlayerForm input {
    font-size: 1.5em;
    margin: 8px;
}

.AddPlayerForm button {
    font-size: 2em;
}

.Games {
    margin: 12px;
}

.NewGame {
    margin: 12px;
}

.NewGame input[type="checkbox"] {
    width: 2.5em;
    height: 2.5em;
}

.NewGame label {
    font-size: 1.5em;
    margin-left: 8px;
    vertical-align: super;
}

.NewGame button {
    font-size: 2em;
}

.Game .TotalScorePanel {
    width: 100%;
    font-size: 1.5em;
}

.Game .TotalScorePanel th {
    width: 50%;
    text-align: right;
}

.Game .HandPanel {
    margin: 20px;
    border-top: 2px solid black;
}

.Game .HandPanel td {
    font-size: 1.5em;
}

.Game .HandPanel td input {
    font-size: 1.2em;
    width: 100%;
}
